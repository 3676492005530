html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype')
}  

::-ms-clear {
  display: none;
}

* {
  word-break: break-word;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  &:focus {
    -moz-outline-style: none;
    outline: none !important;
  }
}
section {
  position: relative;
}
iframe {
  display: block;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  vertical-align: middle;
}
button {
  border: none;
  font-family: 'Raleway';
}
img {
  display: block;
  -ms-interpolation-mode: bicubic;
}
a {
  all: unset;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: inherit;
    text-decoration: none;
  };
}
html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}
body {
  background: #fff;
  font-stretch: normal;
  min-height: 100%;
  font-family: 'Raleway';
  font-variant-numeric: lining-nums;
  position: relative;
  overflow-x: hidden;
}
.clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
input::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  opacity: 0;
}
[readonly] {
  user-select: none;
}
[type=file] {
  max-width: 100%;
}
::after,
::before{
  box-sizing: border-box;
}

b, strong {
  font-weight: bold;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}